import { IonAlert, IonModal, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';

const Home: React.FC = () => {

  const [showModal, setShowModal] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>QuickHuddle</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        The world is your oyster.
        <p>
          If you get lost, the{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/">
            docssss
          </a>{' '}
          will be your guide.
        </p>
        <IonButton onClick={() => setShowAlert1(true)} expand="block">Show Alert 1</IonButton>
        <IonAlert
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          header={'Alert'}
          subHeader={'Subtitle'}
          message={'This is an alert message.'}
          buttons={['OK']}
        />
        <IonModal isOpen={showModal} showBackdrop={false}>
        {/* <img src="http://images.quickhuddle.com/home/v2/ban_4.jpg " style={{width:350}}></img> */}
        <p>This is modal content</p>

        
        <IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton>
      </IonModal>
      <IonButton onClick={() => setShowModal(true)}>Show Modal</IonButton>
    </IonContent>
    </IonPage>
  );
};

export default Home;
